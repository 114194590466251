import React, { useState } from 'react';
import { Form, Input, Checkbox, Button, Modal, notification } from 'antd';
import { useAuth } from '../../hooks/auth';
import { sendCapcha } from '../../services/userApi';
const Login: React.FC = () => {
  const [capcha, setCapcha] = useState('');
  const [isCapcha, setIsCapcha] = useState(false);
  const [capchaImg, setCapchaImg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(1);
  const { login } = useAuth();

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const loginData = await login({ username: values.email, password: values.password, remember: !!values.remember })
    setIsLoading(false);
    if(!loginData) setIsSuccess(0);
    if (loginData.status === 'failure') notification.warning({message: 'Не правильный логин или пароль'})
    if (loginData.status === 'bot') {
      setIsCapcha(true);
      setCapchaImg(loginData.img);
    }
  };

  const onSendCapcha = async () => {
    setIsLoading(true);
    await sendCapcha(capcha);
    setIsLoading(false);
    setIsCapcha(false);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 60 }}>
      {!isSuccess ? 
      <div>
        <h2 style={{ textAlign: 'center', paddingBottom: 20 }}>Доступ к ресурсу ограничен. <br /> Для получения доступа обратитесь к своему МПП.</h2>
      </div> 
      :
      <div style={{ maxWidth: '400px', width: '100%' }}>
        <h2 style={{ textAlign: 'center', paddingBottom: 20 }}>Вход</h2>
        <Form onFinish={onFinish} layout="vertical" className="login-form">
          <Form.Item label="Электронная почта" name="email">
            <Input placeholder="Электронная почта" />
          </Form.Item>
          <Form.Item label="Пароль" name="password">
            <Input.Password placeholder="Пароль" />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Запомнить меня</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit" style={{ width: '100%' }}>
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>}
      <Modal
        closeIcon={false}
        open={isCapcha}
        title={"Выполните действие, что бы продолжить"}
        footer={[<Button type="primary" loading={isLoading} onClick={onSendCapcha}>Отправить</Button>,]}
      >
        <img src={'data:image/png;base64,' + capchaImg} />
        <Input onChange={(e) => setCapcha(e.target.value)} placeholder="Число с картинки" />
      </Modal>
    </div>
  );
};

export default Login;