import React, { useEffect, useState } from 'react';
import { Row, Spin, Breadcrumb } from 'antd';
import ImageCard from '../../../components/ImageWithLabel'
import { categoryListByParent } from '../../../services/categoryApi';
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from '../../../components/CategoryBreadcrumbs';


const Categories: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  let navigate = useNavigate();
  let { id = 0 } = useParams();
  useEffect(() => {
    setIsLoading(true);
    categoryListByParent(+id).then(result => {
      setCategories(result);
      setIsLoading(false);
    });
  }, [id]);
  const handleClick = (categId: number, canAddMaterials: boolean) => {
    if (canAddMaterials) navigate('/learning/materials/' + categId)
    else navigate('/learning/categories/' + categId)
  }
  return (
    <Spin spinning={isLoading}>
      <Breadcrumbs id={id} />
      <Row gutter={[16, 16]}>
        {categories.map((item: any) => (
          <ImageCard src={'/api/public/categories/' + item.imgSource} label={item.name} key={item.id} onClick={() => handleClick(item.id, item.canAddMaterials)}> </ImageCard>
        ))}
      </Row>
    </Spin >
  );
};

export default Categories;
