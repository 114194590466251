import React, { useState, useEffect } from 'react';
import { Row, Col, Checkbox, Button, Input } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { saveTest, questionsList } from '../../services/adminApi';
import { useNavigate, useParams } from "react-router-dom";

const content = [24, 22, 20, 18, 16];
const margin = [0, 1, 2, 3, 4];



const NewTest: React.FC = () => {
  const [questions, setQuestions] = useState([{ id: 0, text: '' }]);
  const [answers, setAnswers] = useState([[{ text: '', isRight: false }]]);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  let { id = 0 } = useParams();

  useEffect(() => {
    if (id) {
      questionsList(+id).then((result: any) => {
        if (result.length > 0) {
          const editedQuestions: any[] = [];
          const editedAnswers: any[] = [];
          result.map((question: any, index: number) => {
            editedQuestions.push({ id: question.id, text: question.text })
            editedAnswers[index] = [];
            question.answers.map((answer: any) => {
              editedAnswers[index].push({ text: answer.text, isRight: answer.isRight })
            })
          })
          setAnswers(editedAnswers);
          setQuestions(editedQuestions);
        }
      })
    }
  }, [])

  const questionChange = (i: number, value: string) => {
    const edited = [...questions];
    edited[i] = { ...edited[i], text: value }
    setQuestions(edited);
  };

  const newQuestion = () => {
    const editedQuestions = [...questions];
    const editedAnswers = [...answers];
    editedAnswers.push([{ text: '', isRight: false }]);
    editedQuestions.push({ id: 0, text: '' });
    setAnswers(editedAnswers);
    setQuestions(editedQuestions);
  };

  const selectChange = (questionIndex: number, i: number, isRight: boolean) => {
    const edited = [...answers];
    edited[questionIndex][i].isRight = isRight;
    setAnswers(edited);
  };

  const labelChange = (questionIndex: number, i: number, label: string) => {
    const edited = [...answers];
    edited[questionIndex][i].text = label
    setAnswers(edited);
  };

  const newAnswer = (questionIndex: number) => {
    const edited = [...answers];
    edited[questionIndex].push({ text: '', isRight: false });
    setAnswers(edited);
  }

  const removeAnswer = (questionIndex: number, i: number) => {
    const edited = [...answers];
    edited[questionIndex].splice(i, 1);
    setAnswers(edited);
  }

  const removeQuestion = (questionIndex: number) => {
    const editedQuestions = [...questions];
    const editedAnswers = [...answers];
    editedQuestions.splice(questionIndex, 1);
    editedAnswers.splice(questionIndex, 1);
    setAnswers(editedAnswers);
    setQuestions(editedQuestions);
  }

  const sendData = async () => {
    if(!answers.every(x=>x.some(y=>y.isRight))) {
      alert('Каждый вопрос должен иметь хотя бы 1 ответ');
      return;
    }
    setIsLoading(true);
    saveTest(+id, questions, answers)
      .then(() => {
        navigate("/admin/materials/"+id);
      })
      .catch(() => {
        console.error('upload failed.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <Row style={{ margin: '30px 0' }}>
      <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
      <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>

        <Row gutter={[16, 16]} >
          <Col span={12} style={{ fontSize: 25 }}>
            {id?'Изменение теста': 'Добавление теста к материалу'}
          </Col>
        </Row>
        {questions.map((question, questionIndex) =>
          <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
            <Col span={24} style={{ fontSize: 20, fontWeight: 600 }}>
              Вопрос №{questionIndex + 1}<span onClick={()=>{removeQuestion(questionIndex)}} style={{color: '#A40207', cursor: 'pointer', float:'right'}}><DeleteOutlined style={{ fontSize: 18 }} /></span>
            </Col>
            <Col span={24}>
              <Input value={question.text} onChange={(e) => questionChange(questionIndex, e.target.value)} />
            </Col>
            <Col style={{ fontSize: 20 }}>
              Варианты ответов
            </Col>
            <Col span={24}>
              <div style={{ fontSize: 18, marginBottom: 5 }}>
                {answers[questionIndex].map((answer, answerIndex) => <div style={{ marginBottom: 20 }}>
                  <Checkbox onChange={(e) => selectChange(questionIndex, answerIndex, e.target.checked)} checked={answer.isRight} style={{ marginRight: 8 }} />
                  <Input value={answer.text} onChange={(e) => labelChange(questionIndex, answerIndex, e.target.value)} style={{ width: 'calc(100% - 45px)' }} />
                  <div onClick={() => removeAnswer(questionIndex, answerIndex)} style={{ float: 'right', color: '#858585', fontSize: 14, paddingTop: 6, cursor: 'pointer' }}><CloseOutlined /></div>
                </div>)}
                <Button onClick={() => newAnswer(questionIndex)} className='pale'>Добавить ответ</Button>
              </div>
            </Col>
          </Row>)}
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col span={12}><Button type='primary' onClick={newQuestion}>Добавить вопрос</Button></Col>
          <Col span={12}><Button type='primary' onClick={sendData} loading={isLoading}>Сохранить</Button></Col>
        </Row>
      </Col>
      <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
      <Link to={'/admin/materials/'+id}><div style={{ position: 'absolute', right: 10, top: 30, color: '#858585', padding: '6px 8px', background: '#CECECE', borderRadius: 20 }}> <CloseOutlined /></div></Link>
    </Row>
  );
};

export default NewTest;
