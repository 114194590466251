import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Layout, Menu, Row, Col } from 'antd';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from './hooks/auth';
import { useOnPress } from './hooks/onButtonPress';

import { mainMenuItems, adminMenuItems, routeItems } from './navigations'
import Login from './pages/login/Login';

const { Header, Content, Footer } = Layout;

const content = [24, 22, 20, 18, 18];
const margin = [0, 1, 2, 3, 3];

interface MenuItemProps {
  to: string;
  label: string | React.ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = ({ to, label }) => {
  const { pathname } = useLocation();
  return (
    <Menu.Item key={to} className={`menu-item ${pathname === to ? 'menu-link-active' : ''}`}>
      <Link to={to} className="menu-link">
        {label}
      </Link>
    </Menu.Item>
  );
};

const App: React.FC = () => {
  const { isAdmin } = useAuth();
  const { onKeyPressed } = useOnPress();
  const [isAdminMode, setIsAdminMode] = useState(adminMenuItems.map(x=>x.to).includes(window.location.pathname));

  useEffect(()=>{if(!isAdmin) setIsAdminMode(false)}, [isAdmin])

  const adminClick = (e: any) => {
    if (e.ctrlKey) return;;
    setIsAdminMode(!isAdminMode);
    
  }
  const MainMenu = () => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? 
    <Row style={{ width: '100%' }}>
      <Col span={16}>
        <Menu className='overflow-left' overflowedIndicator={<MenuOutlined style={{ color: 'white' }} />} theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ backgroundColor: '#A40207' }}>
          {(isAdmin && isAdminMode ? adminMenuItems : mainMenuItems).map((item, key) => (
            <MenuItem key={key} to={item.to} label={item.label} />
          ))}
        </Menu>
      </Col>
      <Col span={8}>
        <Menu overflowedIndicator={<UserOutlined style={{ fontSize: '16px', color: 'white' }} />} theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ backgroundColor: '#A40207', display: 'flex', justifyContent: 'end', maxHeight: 64 }}>
          {!!isAdmin && <Menu.Item className='menu-item'><Link to={isAdminMode?'/learning/categories':'/admin/dashboard'} className="menu-link" onClick={adminClick}> {isAdminMode ? 'Я пользователь' : 'Я админ'}</Link></Menu.Item>}
          <MenuItem to={'https://liderprodag.ru/'} label={'Лидер Продаж'} />
          <MenuItem to={'/profile'} label={<span><UserOutlined style={{ fontSize: '15px' }} /> Профиль</span>} />
        </Menu>
      </Col>
    </Row>
    :
    <Menu className='overflow-left' overflowedIndicator={<MenuOutlined style={{ color: 'white' }} />} theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ backgroundColor: '#A40207' }}>
        <MenuItem to={'/login'} label={'Вход'} />
    </Menu>
  };
  return (
    <BrowserRouter>
      <Layout className="layout" style={{ minHeight: '100vh', outline: 'none' }} onKeyDown={onKeyPressed} tabIndex={0} autoFocus>
        <Row style={{ backgroundColor: '#A40207' }}>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
          <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>
            <Header style={{ backgroundColor: '#A40207' }}>
              <MainMenu />
            </Header>
          </Col>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
        </Row>
        <Row style={{ flex: '1 0 auto', padding: '0 20px' }}>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
          <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>
            <Content>
              <div className="site-layout-content">
                <Routes>
                  <Route path={'/login'} element={<PublicRoute><Login /></PublicRoute>} />
                  {routeItems.map((item) => (
                    <Route key={item.path} path={item.path} element={<PrivateRoute>{item.element}</PrivateRoute>} />
                  ))}
                </Routes>
              </div>
            </Content>
          </Col>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
        </Row>
        <Row style={{ backgroundColor: '#A40207', display: 'flex' }}>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
          <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>
            <Footer style={{ backgroundColor: '#A40207', color: '#fff' }}>
              Copyright ©{new Date().getFullYear()} <a style={{color: 'white', float: 'right'}} href="mailto:safin_ar@resanta.ru">Техподдержка: safin_ar@resanta.ru</a>
            </Footer>
          </Col>
          <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
        </Row>
      </Layout>
    </BrowserRouter>
  );
};

const PrivateRoute = (props: { children: any }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? props.children : <Navigate to="/login" />;
};

const PublicRoute = (props: { children: any }) => {
  const { isAuthenticated } = useAuth();
  return !isAuthenticated ? props.children : <Navigate to="/learning/categories" />;
};


export default App;
