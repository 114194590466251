import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import ProfileContacts from './ProfileContacts';
import ProfileStat from './ProfileStat';
import { useAuth } from '../../hooks/auth';
import { userDataRequest } from '../../services/userApi';

const styles = {
  menuItem: { fontSize: 17, fontWeight: 600, padding: '12px', cursor: 'pointer' },
  menuItemSelect: { background: '#F6E6E7' },
}

const Profile: React.FC = () => {
  const { logout } = useAuth();
  const [activePage, setActivePage] = useState(0);
  const [userData, setUserData] = useState<any>({fio: ''});

  useEffect(()=>{
    userDataRequest().then(result=>{
      setUserData(result);
    })
  }, [])

  return (
    <>
      <div style={{ fontWeight: 600, fontSize: 26, margin: '20px 0' }}>Профиль</div>
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col sm={24} md={8} xxl={6}>
          <Row>
            <Col md={24} sm={12} style={{...styles.menuItem,...activePage===0?styles.menuItemSelect:{}}} onClick={()=>setActivePage(0)}>Контактная информация</Col>
            <Col md={24} sm={12} style={{...styles.menuItem,...activePage===1?styles.menuItemSelect:{}}} onClick={()=>setActivePage(1)}>Статистика обучения</Col>
            <Col md={24} sm={12} style={{...styles.menuItem}} onClick={()=>logout()}> Выход</Col>
          </Row>
        </Col>
        <Col sm={24} md={16} xxl={18}>
         {activePage===0?<ProfileContacts userData={userData}/>:<ProfileStat userData={userData}/>}
        </Col>
      </Row>
    </>
  );
};

export default Profile;
