import { get, post } from './fetch';

export const saveTest = async (materialId: number, questions: any, answers: any): Promise<void> => {
  await post('/test/save', { materialId, questions, answers });
};

export const questionsList = async (id: number): 
Promise<Array<{
  text: string, 
  rightAnswersCount: number, 
  answers: Array<{id: number, text: string, isRight: number }> 
}>> => {
  return await get('/test/questions?id=' + id);
};

export const userPrizes = async (userId: number): 
Promise<{
  success: Array<{
    prize: number, 
    attemptsCount: number, 
    updatedAt: string, 
    name: string
  }>
}> => {
  return await get('/test/userPrizes?userId=' + userId);
};

export const dashboardData = async (period: Array<string>): Promise<{
  allUsersCount: number;
  periodUsersCount: number;
  allAttendanceCount: number;
  periodAttendanceCount: number;
  allTestCount: number;
  periodTestCount: number;
  sumPrize: number;
  periodSumPrize: number;
  attendanceByState: { state: string; count: number }[];
  prizeByState: { state: string; prize: number }[];
  prizeByUser: { fio: string; state: string; prize: number }[];
  allTestsPopularity: { id: number; name: string; imgSource: string; popularity: number }[];
  attendanceChart: { name: string; 'посещаемость': number }[];
}> => {
  return await post('/user/dashboard', { period });
};

export const cleanMaterialStatuses = async (): Promise<[affectedCount: number]> => {
  return await get('/material/clean-statuses');
};

export const getReport = async (state: number | string, period: string[], content: Array<string | number | boolean>): Promise<Blob> => {
  return await post('/admin/report', { state, period, content }, 1, 'blob');
};