import { post, get } from "./fetch";

const setToken = (token: string): void => {
  localStorage.setItem('authToken', token);
};

export const loginRequest = async (credentials: { username: string; password: string, remember: boolean }): Promise<any | boolean> => {
  try {
    const response = await post('/user/login', {credentials});
    const data = response as any;
    if (data && data.token) {
      const token = data.token;
      setToken(token);
    } 
    return data;
  } catch (error) {
    throw new Error('Error occurred during login');
  }
};

export const sendCapcha = async (capcha: string): Promise<any> => {
  try {
    const response = await post('/user/capcha', {capcha});
    const data = response as any;
    return data;
  } catch (error) {
    throw new Error('Error occurred during login');
  }
};

export const userDataRequest = async (): Promise<any> => {
  try {
    return await get('/user/data');
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};

export const userList = async (): Promise<any> => {
  try {
    return await get('/user/list');
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
