import { get, post } from './fetch';

export interface Category {
  id: number;
  name: string;
  imgSource: string;
  parentCategId: number;
  canAddMaterials: boolean;
}

export const categoryListByParent = async (parent:number): Promise<Category[]> => {
  try {
    const categories = await get<Category[]>('/category/list?parent='+parent);
    return categories;
  } catch (error) {
    throw new Error('Error occurred while fetching categories');
  }
};

export const categoryListByCanAddMaterial = async (can:number): Promise<Category[]> => {
  try {
    const categories = await get<Category[]>('/category/list?canAddMaterial='+can);
    return categories;
  } catch (error) {
    throw new Error('Error occurred while fetching categories');
  }
};

export const categoryListTree = async (): Promise<Category[]> => {
  try {
    const categories = await get<Category[]>('/category/list-tree');
    return categories;
  } catch (error) {
    throw new Error('Error occurred while fetching categories');
  }
};

export const createCategory = async (category: Category): Promise<void> => {
  try {
    await post('/categories', category);
  } catch (error) {
    throw new Error('Error occurred while creating the category');
  }
};

export const categoryDelete = async (id: number): Promise<{status:string}> => {
  try {
    const categories = await get<{status:string}>('/category/delete?id='+id);
    return categories;
  } catch (error) {
    throw new Error('Error occurred while fetching categories');
  }
};

export const categoryBreadcrumbs = async (id:number): Promise<Category[]> => {
  try {
    return await get<Category[]>('/category/breadcrumbs?id='+id);
  } catch (error) {
    throw new Error('Error occurred while fetching categories');
  }
};
