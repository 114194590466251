import React from 'react';
import {  Row, Button, Checkbox } from 'antd';
import ImageCard from '../../components/ImageWithLabel'

interface ImageData {
  src: string;
  label: string;
  prize: number;
}

const categoriesData: ImageData[] = [
  {
    src: 'https://mobimg.b-cdn.net/v3/fetch/3f/3f6272cb9708b884077043a397359a52.jpeg',
    label: 'Image 1 Image 1 Image 1 Image 1 Image 1 Image 1',
    prize: 0,
  },
  {
    src: 'https://img-fotki.yandex.ru/get/2713/127908635.50a/0_1164ae_99ff6e1_orig.jpg',
    label: 'Image 2',
    prize: 250,
  },
  {
    src: 'https://vsegda-pomnim.com/uploads/posts/2022-04/1649141608_80-vsegda-pomnim-com-p-samaya-krasivaya-priroda-v-mire-foto-84.jpg',
    label: 'Image 3',
    prize: 0,
  },
  {
    src: 'https://img.fonwall.ru/o/75/priroda-goryi-les-peyzaji.jpg?route=mid&amp;h=750',
    label: 'Image 3',
    prize: 150,
  },
  {
    src: 'https://img-fotki.yandex.ru/get/2713/127908635.50a/0_1164ae_99ff6e1_orig.jpg',
    label: 'Image 2',
    prize: 0,
  },
  {
    src: 'https://mobimg.b-cdn.net/v3/fetch/3f/3f6272cb9708b884077043a397359a52.jpeg',
    label: 'Image 1',
    prize: 0,
  },
  
  {
    src: 'https://vsegda-pomnim.com/uploads/posts/2022-04/1649141608_80-vsegda-pomnim-com-p-samaya-krasivaya-priroda-v-mire-foto-84.jpg',
    label: 'Image 3',
    prize: 0,
  },
  {
    src: 'https://img.fonwall.ru/o/75/priroda-goryi-les-peyzaji.jpg?route=mid&amp;h=750',
    label: 'Image 3',
    prize: 0,
  },
];

const Tests: React.FC = () => {
  return (
      <Row gutter={[16, 16]}>
        {categoriesData.map((item, index) => (
          <ImageCard src={item.src} label={item.label} key={index} onClick={()=>{}}>
            <div style={{marginTop:4, textAlign: 'left'}}>
            { !item.prize ? 
                <>Вы получите за прохождение: <span style={{float: 'right', color: '#A40207'}}>250 баллов</span></> : 
                <><Checkbox checked onClick={()=>false} /> Вы получили: <div style={{float: 'right', color: '#A40207'}}>{item.prize} баллов</div></>
            }
            </div>
            <Button type="primary" style={{ marginTop: '8px' }}>
              Пройти тест
            </Button>
          </ImageCard>
        ))}
      </Row>
  );
};

export default Tests;
