import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './hooks/auth';
import { OnPressProvider } from './hooks/onButtonPress';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <OnPressProvider>
        <App />
      </OnPressProvider>
    </AuthProvider>
  </React.StrictMode>,
);

reportWebVitals();