import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox, Radio, RadioChangeEvent, Button, Modal, Spin } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useParams } from "react-router-dom";
import { oneTest, testStart, sendResult } from '../../services/testApi';
import Result from './Result';
import timeFormat from '../../utils/timeFormat';

const Test: React.FC = () => {
  const [test, setTest] = useState<any>({ testTime: 0, name: '' });
  const [questions, setQuestions] = useState<Array<any>>([]);
  const [currentQuestionNum, setCurrentQuestionNum] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [answers, setAnswers] = useState<any>({});
  const [resultId, setResultId] = useState(0);
  const [result, setResult] = useState<any>(null);
  const [currentTimer, setCurrentTimer] = useState(0);
  const [timerStart, setTimerStart] = useState(0);
  let { id = 0 } = useParams();
  
  useEffect(() => {
    if (currentTimer > 0) setTimeout(() => {
      setCurrentTimer(timerStart - Math.round(Date.now()/1000));
    }, 1000)
    if (isStarted && currentTimer <= 0) sendResult(resultId, answers).then(result => {
      setIsStarted(false);
      setResult(result);
    })
  }, [currentTimer])

  const radioChange = (e: RadioChangeEvent) => {
    setAnswers({ ...answers, [questions[currentQuestionNum].id]: [e.target.value] });
  };

  const selectChange = (e: CheckboxChangeEvent) => {
    const currentQuestionNumId = questions[currentQuestionNum].id;
    const edited = answers[currentQuestionNumId] ? [...answers[currentQuestionNumId]] : [];
    if (e.target.checked) {
      edited.push(e.target.value);
    } else {
      edited.splice(edited.indexOf(e.target.value), 1);
    }
    setAnswers({ ...answers, [currentQuestionNumId]: edited });
  };

  const handleNext = () => {
    if (currentQuestionNum === questions.length - 1) {
      sendResult(resultId, answers).then(result => {
        setIsStarted(false);
        setResult(result);
      })
      return;
    }
    if (answers[questions[currentQuestionNum].id]?.length) {
      setCurrentQuestion(questions[currentQuestionNum + 1])
      setCurrentQuestionNum(currentQuestionNum + 1);
    }
    else Modal.error({ title: 'Нельзя оставлять вопрос без ответа' });
  }

  const handleStart = () => {
    const timerStart = Math.round(Date.now()/1000)+test.testTime;
    setTimerStart(timerStart);
    setCurrentTimer(timerStart - Math.round(Date.now()/1000));
    setIsLoading(true);
    testStart(+id).then(result => {
      setResultId(result.resultId);
      setQuestions(result.test);
      setCurrentQuestion(result.test[0]);
      setIsLoading(false);
      setIsStarted(true);
    })
  }

  useEffect(() => {
    setIsLoading(true);
    oneTest(+id).then(result => {
      setTest(result);
      setIsLoading(false);
    });
  }, [id]);

  return (
    <Spin spinning={isLoading}>
      {isStarted && <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col span={24}>
          <div style={{ fontWeight: 600, fontSize: 25, position: 'absolute' }}>{timeFormat(currentTimer)}</div>
          <div style={{width: '100%'}}><div style={{ fontSize: 25, textAlign: 'center', width: '65%', margin: 'auto' }}>{test.name}</div></div>
        </Col>
      </Row>}
      {isStarted &&
        <Row gutter={[16, 16]} style={{ marginTop: 30, marginBottom: 30 }}>
          <Col xs={24} sm={24} md={24} lg={16} xl={18} xxl={19} style={{ fontSize: 20 }}>
            <div style={{ marginBottom: 10 }}>Вопрос {currentQuestionNum + 1}:</div>
            <div style={{ fontSize: 14, color: '#A40207', marginBottom: 30 }}>{currentQuestion.rightAnswersCount === 1 ? 'Ответ может быть один' : 'Ответов может быть несколько'}</div>
            <div style={{ fontWeight: 600, marginBottom: 20 }}>{currentQuestion.text}:</div>
            <div style={{ fontSize: 18, marginBottom: 5 }}>
              {currentQuestion.rightAnswersCount === 1 ?
                <Radio.Group onChange={radioChange}>
                  {currentQuestion.answers.map((answer: any) => <div style={{ marginBottom: 20 }}><Radio key={answer.id} style={{ fontSize: 18 }} value={answer.id}>{answer.text}</Radio>  </div>)}
                </Radio.Group>
                : currentQuestion.answers.map((answer: any) => <div style={{ marginBottom: 20 }}><Checkbox key={answer.id} onChange={selectChange} value={answer.id}></Checkbox> {answer.text} </div>)
              }
            </div>
            <Button className='pale' onClick={handleNext}>{currentQuestionNum === questions.length - 1 ? 'Завершить' : 'Далее'}</Button>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={5}>
            <Row gutter={[16, 16]}>
              {questions.map((x, i) => {
                const borderColor = i === currentQuestionNum ? '#A40207' : x.answers?.length ? '#F7D5D6' : '';
                return <Col xs={4} sm={4} md={8} lg={8} xl={8}><div style={{ border: borderColor + ' 1px solid', textAlign: 'center', padding: 10, fontSize: 16, backgroundColor: i !== currentQuestionNum && answers[i]?.length ? '#F7D5D6' : '' }}>{i + 1}</div></Col>
              })}
            </Row>
          </Col>
        </Row>
      }
      {!isStarted && !result &&
        <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
          <Col span={24} style={{ fontSize: 18, textAlign: 'center' }}>
            В тесте содержится 15 вопросов. <br /><br />
            На прохождение теста у вас есть {timeFormat(test.testTime)} минут <br /><br />
            {test.attemptsCount<3 && <div>Осталось {3 - test.attemptsCount} {test.attemptsCount<2?'попытки':'попытка'}</div>}
            {test.attemptsCount===3 && <div>У вас не осталось попыток</div>}
            <br />
            {test.attemptsCount<3 ?
              <Button type="primary" onClick={handleStart} style={{ marginTop: 5, marginBottom: 20 }}>
                Начать тест
              </Button>
              :
              <div>Вы можете попробовать снова после: <b>{test.nextAttempt}</b></div>
            }
          </Col>
        </Row>
      }
      {result &&
        <Result testId={id} result={result} answers={answers} test={test} questions={questions} />
      }
    </Spin>
  );
};

export default Test;
