import Login from './pages/login/Login'
import Categories from './pages/learning/categories/Categories';
import Materials from './pages/learning/materials/Materials';
import Material from './pages/learning/material/Material';
import Tests from './pages/tests/Tests';
import Test from './pages/test/Test';
import Profile from './pages/profile/Profile';
import NewTest from './pages/admin/NewTest';
import NewMaterial from './pages/admin/NewMaterial';
import AdminMaterials from './pages/admin/Materials';
import AdminUsers from './pages/admin/Users';
import AdminDashboard from './pages/admin/dashboard/Dashboard';
import AdminCategories from './pages/admin/Categories';
import Reports from './pages/admin/Reports';

export const mainMenuItems = [
  { to: '/learning/categories', label: 'Материалы' },
  { to: '/learning/materials', label: 'На изучении' },
];

export const adminMenuItems = [
  { to: '/admin/dashboard', label: 'Статистика' },
  { to: '/admin/materials', label: 'Материалы' },
  { to: '/admin/categories', label: 'Категории' },
  { to: '/admin/users', label: 'Пользователи' },
  { to: '/admin/reports', label: 'Отчеты' },
];

export const routeItems = [
  { path: '/', element: <Categories /> },
  { path: '/login', element: <Login /> },
  { path: '/learning/categories', element: <Categories /> },
  { path: '/learning/categories/:id', element: <Categories /> },
  { path: '/learning/materials/:id', element: <Materials isLearning={0} /> },
  { path: '/learning/materials', element: <Materials isLearning={1} /> },
  { path: '/learning/material/:id', element: <Material /> },
  { path: '/tests', element: <Tests /> },
  { path: '/test/:id', element: <Test /> },
  { path: '/profile', element: <Profile /> },
  { path: '/admin/new-test/:id', element: <NewTest /> },
  { path: '/admin/new-material', element: <NewMaterial /> },
  { path: '/admin/new-material/:id', element: <NewMaterial /> },
  { path: '/admin/materials', element: <AdminMaterials /> },
  { path: '/admin/materials/:id', element: <AdminMaterials /> },
  { path: '/admin/users', element: <AdminUsers /> },
  { path: '/admin/dashboard', element: <AdminDashboard /> },
  { path: '/admin/categories', element: <AdminCategories /> },
  { path: '/admin/reports', element: <Reports /> },
];