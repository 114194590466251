import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';

interface ImageUploaderProps {
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}

const Uploader: React.FC<ImageUploaderProps> = ({ fileList, setFileList }) => {
  const [pictureList, setpictureList] = useState<UploadFile[]>(fileList);
  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newPictureList }) =>
    setpictureList(newPictureList);

  return (
    <>
      <Upload
        {...props}
        fileList={pictureList}
        onChange={handleChange}
      >
        {fileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>Select File</Button>}
      </Upload>
    </>
  );
};

export default Uploader;