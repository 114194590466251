import React, { createContext, useContext, useState } from 'react';

interface OnPressContextType {
  keyPressed: any;
  onKeyPressed: (e: any) => void;
}

interface OnPressProviderProps {
  children: React.ReactNode;
}

const OnPressContext = createContext<OnPressContextType | undefined>(undefined);

export const OnPressProvider: React.FC<OnPressProviderProps> = ({ children }) => {
  const [keyPressed, setKeyPressed] = useState<any>(null);

  const onKeyPressed = async (e: any) => {
    setKeyPressed(e)
  };

  return (
    <OnPressContext.Provider value={{ keyPressed, onKeyPressed }}>
      {children}
    </OnPressContext.Provider>
  );
};

export const useOnPress = () => {
  const context = useContext(OnPressContext);
  if (context === undefined) {
    throw new Error('useOnPress must be used within an OnPressProvider');
  }
  return context;
};