import React from 'react';
import { Row, Col } from 'antd';

interface Contacts {
  userData: any
}

const ProfileContacts: React.FC<Contacts> = (props) => {
  const { userData } = props;
  const fio = props.userData.fio.split(' ');
  return (
    <>
      <Row gutter={[16, 25]} style={{ padding: '0 40px' }}>
        <Col sm={24} xl={12}>
          <div><b>Имя</b></div>
          {fio[0]}
        </Col>
        <Col sm={24} xl={12}>
          <div><b>Фамилия</b></div>
          {fio[1]}
        </Col>
        <Col sm={24} xl={12}>
          <div><b>Отчество</b></div>
          {fio[2]||'-'}
        </Col>
        <Col sm={24} xl={12}>
          <div><b>Телефон</b></div>
          {userData.phone||'-'}
        </Col>
        <Col span={24}>
          <div><b>Почта</b></div>
          {userData.email||'-'}
        </Col>
      </Row>
    </>
  );
};

export default ProfileContacts;