import React, { useState } from 'react';
import { Col } from 'antd';
import ResizeObserver from 'rc-resize-observer';

interface CardProps {
  children: React.ReactNode,
  src: string,
  label: string,
  key: number,
  onClick: Function | null,
}

const ImageCard = (props: CardProps) => {
  const [imgHeight, setImgHeight] = useState(200);

  const resize = ({ width, height }: any) => {
    setTimeout(() => {
      setImgHeight(width / 1.6)
    }, 0);
  }
  return (
    <ResizeObserver onResize={resize}>
      <Col onClick={() => props.onClick ? props.onClick() : null} key={props.key} xs={24} sm={12} md={12} lg={8} xl={6} style={props.onClick ? { cursor: 'pointer' } : undefined}>
        <div style={{ marginTop: 10, position: 'relative', textAlign: 'center', fontFamily: 'Mulish' }}>
          <div style={{ display: 'flex', justifyContent: 'center', height: 62 }}>
            <h3 style={{ display: 'flex', alignSelf: 'center', marginBlock: 0, fontSize: 20 }}>{props.label}</h3>
          </div>
          <img alt={props.label} src={props.src} style={{ width: '100%', height: imgHeight, objectFit: 'cover' }} />
          {props.children}
        </div>
      </Col>
    </ResizeObserver>
  );
};

export default ImageCard;
