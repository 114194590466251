import React, { createContext, useContext, useState } from 'react';
import { loginRequest } from '../services/userApi';

interface AuthContextType {
  isAdmin: any;
  isAuthenticated: boolean;
  login: (credentials: any) => any;
  logout: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const removeTokens = (): void => {
  localStorage.removeItem('authToken');
  localStorage.setItem('isAdmin', '0');
  localStorage.setItem('isAdminMode', '0');
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));
  const [isAdmin, setIsAdmin] = useState<any>(localStorage.getItem('isAdmin'));

  const login = async (credentials: any) => {
    const userData = await loginRequest(credentials);
    if(userData?.user?.access === 0) {
      logout();
      return false;
    }
    if(userData.status === 'success') {
      localStorage.setItem('isAdmin', userData?.user?.isAdmin)
      setIsAdmin(userData?.user?.isAdmin);
      setIsAuthenticated(true);
    }
    return userData;
  };

  const logout = () => {
    removeTokens();
    setIsAuthenticated(false);
    setIsAdmin(0);
  };

  return (
    <AuthContext.Provider value={{ isAdmin, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};