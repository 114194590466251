import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Table, Spin } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { materialList, deleteMaterial, publishMaterial } from '../../services/materialsApi';
import { cleanMaterialStatuses } from '../../services/adminApi';
import { useParams, Link } from 'react-router-dom';

interface DataType {
  id: number;
  key: string;
  name: string;
  questionsAmount: number;
  isPublished: boolean;
}

const Materials: React.FC = () => {
  useEffect(() => { getData() }, [])
  const getData = () => {
    setIsLoading(true);
    materialList(0, 0).then(data => {
      setData(data);
      setFullData(data);
      setIsLoading(false);
    });
  }
  const [data, setData] = useState<any>([]);
  const [fullData, setFullData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let { id = 0 } = useParams();

  const handleDelete = (id: number) => {
    if (window.confirm('Вы уверены?')) deleteMaterial(id).then(() => {
      getData()
    })
  }

  const handlePublish = (id: number, status: boolean) => {
    publishMaterial(id, status).then(() => {
      getData()
    })
  }

  const handleCleanStatuses = () => {
    cleanMaterialStatuses().then(() => {
      alert('Статусы обучения сброшены')
    })
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => <div>{text}<Link style={{ float: 'right', fontSize: 18 }} to={'/admin/new-material/' + row.id}><EditOutlined /></Link></div>,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Вопросы',
      dataIndex: 'questionsAmount',
      key: 'questionsAmount',
      sorter: (a, b) => a['questionsAmount'] - b['questionsAmount'],
      render: (text, row) => <div>{text}<Link style={{ float: 'right', fontSize: 18 }} to={'/admin/new-test/' + row.id}><EditOutlined /></Link></div>,
    },
    {
      title: '',
      key: 'id',
      render: (row) => <Row gutter={[0, 5]} style={{ maxWidth: 190, float: 'right' }}>
        <Col>
          <Col xs={24} sm={0}><span onClick={() => handlePublish(row.id, !row.isPublished)} className={row.isPublished ? 'red-badge' : 'green-badge'}>{row.isPublished ? 'Блок' : 'Разб'}</span></Col>
          <Col xs={0} sm={24}><span onClick={() => handlePublish(row.id, !row.isPublished)} className={row.isPublished ? 'red-badge' : 'green-badge'}>{row.isPublished ? 'Заблокировать' : 'Опубликовать'}</span></Col>
        </Col>
        <Col><span onClick={() => handleDelete(row.id)} style={{ color: '#A40207', cursor: 'pointer', marginLeft: 15 }}><DeleteOutlined style={{ fontSize: 18 }} /></span></Col>
      </Row>
    },
  ];

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    if (value.length > 2) setData(fullData.filter((x: DataType) => x.name.toLowerCase().indexOf(value) >= 0))
    else setData(fullData)
  }

  const defaultCurrent = Math.ceil((data.findIndex((x: any) => x.id === Number(id)) + 1) / 10);
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }} >
        <Col span={24} style={{ fontSize: 25 }}>
          Материалы
        </Col>
        <Col sm={4} xs={12}><Button onClick={handleCleanStatuses} style={{ width: '100%' }}> Сброс статусов </Button></Col>
        <Col sm={12} xs={12}><Input style={{ height: 35 }} onChange={handleSearch} placeholder='Поиск по названию (мин 3 символа)' /></Col>
        <Col sm={8} xs={24}><Link to={'/admin/new-material'}><Button style={{ width: '100%' }}> Новый материал <PlusOutlined /></Button></Link></Col>
        {!!data.length && <Col span={24}><Table columns={columns} dataSource={data} pagination={{ defaultCurrent: defaultCurrent > 0 ? defaultCurrent : 1 }} /></Col>}
      </Row>
    </Spin>
  );
};

export default Materials;
