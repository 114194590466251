import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Select, Table, Spin } from 'antd';
import { userList } from '../../services/userApi';

const columns = [
  {
    title: 'Имя пользователя',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Баланс',
    dataIndex: 'balance',
    key: 'balance',
  },
  {
    title: 'ФИО',
    dataIndex: 'fio',
    key: 'fio'
  },
  {
    title: 'Филиал',
    dataIndex: 'stateName',
    key: 'stateName',
    sorter: (a: any, b: any) => a.stateName.localeCompare(b.stateName),
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Админ',
    dataIndex: 'isAdmin',
    key: 'isAdmin',
    render: (val: boolean) => val ? 'Да' : 'Нет',
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },  
];

const Users: React.FC = () => {
  const [users, setUsers] = useState([]);
  const [states, setStates] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [state, setState] = useState<number | string>(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    userList().then(result => {
      setIsLoading(false);
      setUsers(result.map((x: any) => ({ ...x, key: x.id })));
      setStates(Array.from(new Set(result.map((x: any) => x.stateName))))
    });
  }, [])
  const filterUsers = (users: any) => {
    let result = users;
    if (search.length >= 3) result = result.filter((user: any) => user.name?.toLowerCase()?.indexOf(search) >= 0 || user.email?.toLowerCase()?.indexOf(search) >= 0 || user.fio?.toLowerCase()?.indexOf(search) >= 0 || user.phone?.toLowerCase()?.indexOf(search) >= 0);
    if (state) result = result.filter((user: any) => user.stateName === state);
    return result;
  }
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }} >
        <Col span={24} style={{ fontSize: 25 }}>
          Список пользователей
        </Col>
        <Col sm={16} xs={24}>Поиск:<Input style={{ marginTop: 7 }} placeholder='Имя пользователя / E-mail / ФИО / Телефон' onChange={(e) => { setSearch(e.target.value.toLowerCase()) }} /></Col>
        <Col sm={8} xs={24}>Филиал:<Select style={{ marginTop: 7, width: '100%' }} defaultValue={0} options={[{ label: 'Выберите филиал', value: 0 }, ...states.map(x => ({ value: x, label: x })).sort((a, b) => a.value.localeCompare(b.value))]} onChange={setState} /></Col>
        <Col span={24}>
          <div style={{ fontSize: 14, marginBottom: 10 }}>Всего пользователей: {users.length}</div>
          <Table
            scroll={{ x: 300 }}
            columns={columns}
            dataSource={filterUsers(users)}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default Users;
