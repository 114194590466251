import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spin } from 'antd';
import { useParams, Link } from "react-router-dom";
import { oneMaterial } from '../../../services/materialsApi';
import ResizeObserver from 'rc-resize-observer';
import { RightOutlined, LeftOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useOnPress } from '../../../hooks/onButtonPress';
import Breadcrumbs from '../../../components/CategoryBreadcrumbs';

import { Document, Page, pdfjs } from 'react-pdf';
import "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const Material: React.FC = () => {
  const fullScreen = useFullScreenHandle();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pdfWidth, setPdfWidth] = useState(0);
  const [pdfHeight, setPdfHeight] = useState(0);
  const [material, setMaterial] = useState({ categoryId: 0, name: '', timeForLearn: 0, html: '', fileSource: '', prize: 0, lastAttempt: { attemptsCount: 0, resetDate: '' }, lastPrizeAttempt: { isPrizeAvailable: true, prizeAvailableDate: '' } });
  let { id = 0 } = useParams();
  const { keyPressed, onKeyPressed } = useOnPress();
  const [isArrowsVisible, setIsArrowsVisible] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    oneMaterial(+id, 1).then(result => {
      setMaterial(result);
      setIsLoading(false);
    });
  }, [id]);
  useEffect(() => {
    if (keyPressed?.key === 'ArrowRight') nextSlide();
    if (keyPressed?.key === 'ArrowLeft') prevSlide();
  }, [keyPressed]);
  const resize = ({ width, height }: any) => {
    setTimeout(() => {
      setPdfWidth(width)
      setPdfHeight(width / 3 * 2)
    }, 0);
  }
  const FS: React.FC = () => {
    return <span style={{ marginRight: 10, fontSize: 22, cursor: 'pointer' }} onClick={fullScreen.active ? fullScreen.exit : fullScreen.enter}>
      {fullScreen.active ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
    </span>
  }
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  const hideArrows = () => setTimeout(() => setIsArrowsVisible(0), 1000)
  const prevSlide = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      setIsArrowsVisible(1);
      hideArrows()
    }
  }
  const nextSlide = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      setIsArrowsVisible(2);
      hideArrows()
    }
  }
  const paginationButtonStyle = { padding: '2px 4px 3px', borderRadius: 10, cursor: 'pointer', color: 'white', fontSize: 50, top: '45%', background: 'rgba(0, 0, 0, 0.4)' };
  return (
    <Spin spinning={isLoading} >
      <Breadcrumbs id={material.categoryId} lastItem={material.name} />
      <Row>
        <Col span={24} xl={{ span: 20, offset: 2 }} >
          <h1 style={{ fontSize: 25 }}>{material.name}</h1>
          <div style={{ fontSize: 18 }}>Время на изучение: {material.timeForLearn} минут</div>
          <div className='material-html noselect' style={{ marginTop: 25 }} dangerouslySetInnerHTML={{ __html: material.html }} />
          {material.fileSource && <div style={{ position: 'relative' }}>
            <ResizeObserver onResize={resize}>
              <FullScreen handle={fullScreen}>
                <div onKeyDown={onKeyPressed} onMouseLeave={() => setIsArrowsVisible(0)} tabIndex={0} style={{ outline: 'none' }}>
                  <div onClick={prevSlide} onMouseMove={() => setIsArrowsVisible(1)} className='pang-wrap' style={{ position: 'absolute', height: '100%', width: '50%', left: 0, zIndex: 999 }}>
                    <div style={{ ...paginationButtonStyle, position: 'absolute', visibility: isArrowsVisible === 1 ? 'visible' : 'hidden' }}><LeftOutlined /></div>
                  </div>
                  <div onClick={nextSlide} onMouseMove={() => setIsArrowsVisible(2)} className='pang-wrap' style={{ position: 'absolute', height: '100%', width: '50%', left: '50%', zIndex: 999 }}>
                    <div style={{ ...paginationButtonStyle, position: 'absolute', right: 0, visibility: isArrowsVisible === 2 ? 'visible' : 'hidden' }}><RightOutlined /></div>
                  </div>
                  <div style={{ background: 'rgba(0, 0, 0, 0.4)', padding: '5px 0px 5px 11px', textAlign: 'right', color: 'white', position: 'absolute', top: 0, right: 0, zIndex: 999, borderBottomLeftRadius: 5 }}>
                    <span style={{ fontSize: 20, marginRight: 9 }}>{pageNumber} / {numPages}</span>
                    <FS />
                  </div>
                  <div className={'noselect'} style={{ minHeight: pdfHeight - 100, zIndex: 0 }}>
                    <Document file={'/api/public/materials/file/' + material.fileSource} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page width={pdfWidth} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
                    </Document>
                  </div>
                </div>
              </FullScreen>
            </ResizeObserver>
          </div>}
          <div style={{ textAlign: 'center', marginTop: 25, lineHeight: 1.5 }}>
            {!material.lastPrizeAttempt.isPrizeAvailable && material.lastAttempt.attemptsCount < 3 && <div style={{ color: '#22C55E' }}>Тест успешно пройден!</div>}
            <div>Доступно попыток: {3 - material.lastAttempt.attemptsCount} из 3</div>
            {material.lastAttempt.attemptsCount === 3 && <div style={{ color: '#A40207' }}>Тест заблокирован до: {material.lastAttempt.resetDate}</div>}
            {material.lastAttempt.attemptsCount < 3 &&
              <div>
                Баллов за прохождение теста: <span style={{ color: '#A40207' }}>+{material.lastPrizeAttempt.isPrizeAvailable ? material.prize : 0}</span>
                {!material.lastPrizeAttempt.isPrizeAvailable && <span> (до {material.lastPrizeAttempt.prizeAvailableDate})</span>}
              </div>
            }
            <Link to={"/test/" + id}><Button disabled={!(material.lastAttempt.attemptsCount < 3)} type="primary" style={{ marginTop: 5, marginBottom: 20 }}>
              Начать тест
            </Button></Link>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default Material;
