import React from 'react';
import { Row, Col, Button } from 'antd';
import { CloseOutlined, CheckOutlined, RightCircleOutlined } from '@ant-design/icons';
import timeFormat from '../../utils/timeFormat';
import { useNavigate } from "react-router-dom";

const styles = {
  leftCardText: { width: 'calc(100% - 45px)', marginBottom: 20, fontSize: 18 },
  leftCardValues: { width: 45, marginBottom: 20, fontSize: 18, fontWeight: 600 },
  rightCardText: { fontSize: 18, marginBottom: 20, },
  answerText: { fontSize: 16, padding: 10, },
  questionText: { fontSize: 18, padding: 10, fontWeight: 600 },
  mR: { marginRight: 10 },
  iconContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  iconCheck: { width: 50, height: 50, borderRadius: 50, lineHeight: '50px', background: '#DCFCE7', fontSize: 22, color: '#14532D' },
  iconCross: { width: 50, height: 50, borderRadius: 50, lineHeight: '50px', background: '#F6E6E7', fontSize: 22, textAlign: 'center', color: '#A40207' }
}

interface ResultProps {
  testId: number | string;
  result: any;
  answers: any;
  test: any;
  questions: any;
}

const Result: React.FC<ResultProps> = ({ testId, test, result, answers, questions }) => {
  let navigate = useNavigate();
  const questionsLength = questions.length;
  const correctnessPercent = result.correctnessRate ? Math.round(result.correctnessRate / questionsLength * 100) : 0;
  const backToLearn = () => {
    navigate('/learning/categories')
  }
  const tryAgain = () => {
    window.location.reload();
  }
  return (
    <>
      <Row gutter={[16, 16]} style={{ marginTop: 40, marginBottom: 20 }}>
        <Col sm={24} xl={8}><div style={{ fontWeight: 600, fontSize: 25 }}>Итоги аттестации</div></Col>
        <Col sm={24} xl={16}>
          <div style={{ fontSize: 25 }}>{test.name}</div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 30, marginBottom: 20 }}>
        <Col sm={24} xl={8}>
          <Row className={result.isSuccess ? 'green-card' : 'red-card'}>
            <Col style={styles.leftCardText}>Результат:</Col><Col style={{ ...styles.leftCardValues, color: result.isSuccess ? '#169F4C' : '#A40207' }}>{correctnessPercent}%</Col>
            <Col style={styles.leftCardText}>Количество неправильных ответов:</Col><Col style={styles.leftCardValues}>{questionsLength - result.correctnessRate}</Col>
            <Col style={styles.leftCardText}>Затраченное время:</Col><Col style={styles.leftCardValues}>{timeFormat(result.duration)}</Col>
            {!result.isSuccess && <Button type="primary" size='large' onClick={tryAgain}>Пройти заново</Button>}
          </Row>
        </Col>
        <Col sm={24} xl={16}>
          {result.isSuccess ? <div className='green-card'>
            <div style={{ ...styles.rightCardText, color: '#169F4C', fontWeight: 600 }}>Тест успешно пройден. Поздравляем!</div>
            <div style={styles.rightCardText}>Начислено <b>{result.prize}</b> баллов</div>
            <Button type="primary" size='large' onClick={backToLearn}>Вернуться к обучению <RightCircleOutlined /></Button>
          </div> :
            <div className='red-card'>
              <div style={{ ...styles.rightCardText, color: '#A40207', fontWeight: 600 }}>Тест не пройден</div>
              <div style={styles.rightCardText}>Для успешного прохождения результат должен быть <b>не менее 80%</b></div>
              <div style={styles.rightCardText}>Изучите материал курса еще раз и попробуйте пройти аттестацию снова.</div>
              <Button type="primary" size='large' onClick={backToLearn}>Вернуться к обучению <RightCircleOutlined /></Button>
            </div>}
        </Col>
      </Row>
      {questions.map((question: any, i: number) => {
        if (result.answers[question.id]?.isRight) return <Row className='noselect' style={{ padding: '20px 0', marginBottom: 20 }}>
          <Col xs={24} sm={2} style={styles.iconContainer}><div style={{ ...styles.iconCheck, textAlign: 'center' }}><CheckOutlined /></div></Col>
          <Col xs={24} sm={22}>
            <div style={styles.questionText}><b style={styles.mR}>Вопрос {i + 1}: </b> {question.text}:</div>
            <Row style={styles.answerText}>
              <Col style={{ ...styles.mR, width: '90px' }}>Ваш ответ:</Col> <Col style={{ width: 'calc( 100% - 110px)' }}>{answers[question.id].map((answerId: number) => <div>{result.answers[question.id].answers[answerId].text}</div>)}</Col>
            </Row>
          </Col>
        </Row>
        else return <Row className='noselect' style={{ padding: '20px 0', marginBottom: 20, border: '2px solid #F6E6E7', borderRadius: 8 }}>
          <Col xs={24} sm={2} style={styles.iconContainer}><div style={{ ...styles.iconCross, textAlign: 'center' }}><CloseOutlined /></div></Col>
          <Col xs={24} sm={22}>
            <div style={styles.questionText}><b style={styles.mR}>Вопрос {i + 1}: </b> {question.text}:</div>
            <Row style={styles.answerText}>
              <Col style={{ ...styles.mR, width: '90px' }}>Ваш ответ:</Col> <Col style={{ width: 'calc( 100% - 100px)' }}>{answers[question.id]?.map((answerId: number) => <div>{result.answers[question.id].answers[answerId].text}</div>)}</Col>
            </Row>
            <Row style={styles.answerText}>
              <Col style={{ ...styles.mR, width: '150px' }}>Правильный ответ:</Col> <Col style={{ width: 'calc( 100% - 160px)' }}>{Object.values(result.answers[question.id].answers).filter((x: any) => x.isRight).map((x: any) => <div>{x.text}</div>)}</Col>
            </Row>
          </Col >
        </Row >
      })}
    </>
  );
};

export default Result;
