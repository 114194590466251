import { get, post } from './fetch';

export interface Test {
  name: string;
  prize: number;
  testTime: number;
}
export interface Questions {
  questions: Array<any>;
}
export const sendResult = async (resultId: number, answers: any): Promise<Test[]> => {
  try {
    return await post<Test[]>('/test/result', {resultId, answers});
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
export const testStart = async (id: number): Promise<any> => {
  try {
    return await get<any>('/test/start?materialId='+id);
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
export const oneTest = async (id: number): Promise<Test> => {
  try {
    return await get<Test>('/test/one?id='+id);
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};
export const testStat = async () => {
  try {
    return await get<any>(`/test/stat`);
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};

